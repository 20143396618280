import * as Sentry from "@sentry/browser";

if (window.SENTRY_DSN) {
  Sentry.init({
    dsn: window.SENTRY_DSN,
    environment: window.MONOGRAPH_ENV,
    ignoreErrors: [
      "ResizeObserver loop completed with undelivered notifications",
      "ResizeObserver loop limit exceeded",
      "NS_ERROR_NOT_INITIALIZED",
      "TypeError: Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "offsetTop",
      // https://github.com/remix-run/react-router/issues/8834#issuecomment-1118083034
      "The node to be removed is not a child",
    ],
    integrations: [Sentry.browserTracingIntegration()],
    maxBreadcrumbs: 50,
    tracesSampleRate: 0.25,
  });

  if (sentryUserInfo.email !== undefined) {
    Sentry.setUser(sentryUserInfo);
  }
}
